<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardBody>
          <CRow class="card-header-row">
            <CCol md="3" sm="3">
              <div class="card-header-text">{{ $t("USER_MANAGEMENT.INVITE_USER.USER_LIST") }}</div>
            </CCol>
            <CCol md="9">
              <CButton
                class="add-user-button float-right"
                color="durianprimary"
                @click="goTo('/user/invite')"
                v-element-access="permissions.userManagementCreate"
              >
                <CIcon name="cil-library-add" />
                &nbsp;
                {{ $t("USER_MANAGEMENT.INVITE_USER.NEW_INVITE") }}
              </CButton>
              <CInput
                class="search-input float-right"
                :placeholder="$t('COMMON.SEARCH')"
                v-model="search"
                @input="getUsers"
              ></CInput>
            </CCol>
          </CRow>
          <CDataTable
            class="col-md-12 p-0 pointer-on-hover table"
            hover
            :items="users"
            :fields="fields"
          >
            <template #name="data">
              <td>{{ data.item.name }}</td>
            </template>
            <template #email="data">
              <td>{{ data.item.email }}</td>
            </template>
            <template #role="data">
              <td>{{ data.item.role_name }}</td>
            </template>
            <template #status="data">
              <td>
                <CBadge
                  :color="getUserStatusBadge(data.item.is_active)"
                  class="pointer-on-hover status-badge user-status"
                >{{ getStatusCapitalized(getUserStatus(data.item.is_active)) }}</CBadge>
              </td>
            </template>
          </CDataTable>
          <b-pagination
            size="md"
            align="center"
            @change="pageChange"
            :per-page="limit"
            :total-rows="totalRows"
            v-model="pageNumber"
          ></b-pagination>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getUserList } from "@/api/user_management.api.js";
const ACTIVE_STATUS = "Active";
const PENDING_STATUS = "Pending";
export default {
  name: "UserList",
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("USER_MANAGEMENT.TABLE.NAME"),
        },
        {
          key: "email",
          label: this.$t("USER_MANAGEMENT.TABLE.EMAIL"),
        },
        {
          key: "role",
          label: this.$t("USER_MANAGEMENT.TABLE.ROLE"),
        },
        {
          key: "status",
          label: this.$t("USER_MANAGEMENT.TABLE.STATUS"),
        }
      ],
      users: [],
      totalRows: 0,
      search: "",
      skip: 0,
      limit: 20,
      pageNumber: 1
    };
  },
  methods: {
    getUserStatus(isActive) {
      if (isActive) {
        return ACTIVE_STATUS;
      }
      return PENDING_STATUS;
    },
    getUserStatusBadge(isActive) {
      if (isActive) {
        return "success";
      }
      return "warning";
    },
    async getUsers() {
      try {
        const payload = {
          search: this.search,
          skip: this.skip,
          limit: this.limit
        };
        const response = await getUserList(payload);
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.users = data.users;
          this.totalRows = data.count;
        } else {
          this.showToaster(
            this.$t("USER_MANAGEMENT.ERRORS.ERROR_GETTING_USER_LIST")
          );
        }
      } catch (error) {
        this.showToaster(
          this.$t("USER_MANAGEMENT.ERRORS.ERROR_GETTING_USER_LIST")
        );
      }
    },
    pageChange(val) {
      this.skip = (val - 1) * this.limit;
      this.getUsers();
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style scoped>
.card-header-text {
  font-size: 18px;
  font-weight: bold;
  margin-top: 1.8%;
  margin-left: 2%;
}
.user-status {
  font-size: 14px;
}
.search-input {
  margin-right: 3%;
  width: 30%;
}
.status-badge {
  font-size: 12px;
}
.card-header-row {
  border-bottom: 1px solid #d9dbdf;
}
.table {
  margin-top: 2%;
}
</style>